import React, { useEffect } from "react";
import "./ListItemML.scss";
import Card from "react-bootstrap/Card";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Stack from "@mui/material/Stack";
import { Col, Container, Row } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { PlayArrow } from "@mui/icons-material";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Navbar from "../../Pages/NavBar";
import axios from "axios";
import BasicModel from "../../Components/ListItemML/popdescription/BasicModal";
import { RowDetailState } from "@devexpress/dx-react-grid";
import { useContext } from "react";
import { Contexts } from "../../../contexts/Contexts";
import { withStyles } from "@material-ui/core/styles";
import {
  TreeDataState,
  CustomTreeData,
  DataTypeProvider,
} from "@devexpress/dx-react-grid";
import {
  Table as TableDevExpress,
  Grid,
  TableHeaderRow,
  TableTreeColumn,
} from "@devexpress/dx-react-grid-material-ui";
import { CloudDownload } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import Assessment from "../Contents/Assessment/Assessment";
import MenuItem from "@mui/material/MenuItem";
import MuiMenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import BasicModal from "./popdescription/BasicModal";
import {
  PUBLIC_URL,
  LMS_DEV,
  LMS_QA,
  PUBLIC_URL_API2,
  formatTime,
  DOMAIN_ID,
  LMS
} from "./../Common/Constants.js";
import swal from "sweetalert";

const getChildRows = (row, rootRows) => (row ? row.items : rootRows);

function ClassRoomRowMUI(props: { row: ReturnType<typeof createData> }) {
  const { row } = props;
  const { t } = useTranslation();
  function removeTags(str) {
    if (str === null || str === "" || str === undefined) return "NA";
    else str = str.toString();

    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace(/(<([^>]+)>)/gi, " ");
  }
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell component="th" scope="row">
          {row.VC_SSN_NM}
        </TableCell>

        <TableCell align="right" style={{ maxWidth: 200 }}>
          <p title={removeTags(row.VC_DSCRPTN)}>
            {row.VC_DSCRPTN != null
              ? removeTags(row.VC_DSCRPTN).substring(0, 20) + "..."
              : "NA"}
          </p>
        </TableCell>
        <TableCell align="right">
          {formatTime(row?.DT_SSN_DT, "date")}
        </TableCell>
        <TableCell align="right">
          {formatTime(row?.DT_STRT_TM, "time")}
        </TableCell>
        <TableCell align="right">
          {" "}
          {formatTime(row?.DT_END_TM, "time")}
          {/* {row?.DT_END_TM?.split("T")[1]} */}
        </TableCell>
        <TableCell align="right">{row.VC_VN_NM}</TableCell>
        <TableCell align="right">{row.VC_INSTRCTR_NM}</TableCell>
        <TableCell align="right">{row.VC_FL_NM}</TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export const ListItemML = (props) => {
  const [elearningProgramGrid, setelearningProgramGrid] = useState(true);
  const [classroomTrainingGrid, setclassroomTrainingGrid] = useState(false);

  const [classroomData, setclassroomData] = useState([]);
  const [programData, setprogramData] = useState([]);
  const { EL_P_ML_data } = useContext(Contexts);
  const { setEL_P_ML_data } = useContext(Contexts);
  const [elearning_programNodesCount, setelearning_programNodesCount] =
    useState(0);
  const [pageLevelCourseProgress, setpageLevelCourseProgress] = useState(0);
  const [popup, setPopup] = useState(false);
  const [topic, setTopic] = useState("");
  const [assessmentDetails, setAssessmentDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dialogBoxOpen, setdialogBoxOpen] = useState(false);
  const [withdrawalReason, setwithdrawalReason] = useState("");
  const [withdrawDropdownHasError, setwithdrawDropdownHasError] =
    useState(false);
  const [withdrawDropdownValue, setwithdrawDropdownValue] = useState("");
  const [withdrawReasonTextHasError, setwithdrawReasonTextHasError] =
    useState(false);

  const { setgblEnrollDisable } = useContext(Contexts);
  const [EL_P_ExpiredFlag, setEL_P_ExpiredFlag] = useState(false);
  const [isValidationErrorPresent, setisValidationErrorPresent] =
    useState(false);
  const [validationErrorMessage, setvalidationErrorMessage] = useState();
  const [currentNominationStatus, setcurrentNominationStatus] = useState();
  const [status, setStatus] = useState();
  const [IPAddress, setIPAddress] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  let nodeId = 0;

  // const [certIdLocal, setcertIdLocal] = useState();
  useEffect(() => {
    console.log("location FROM DETAILS ML", location);
  }, [location]);
  const [showWithdrawButton, setShowWithdrawButton] = useState(true);

  const [isOpen, setIsOpen] = useState(false);
  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  function removeTags(str) {
    if (str === null || str === "" || str === undefined) return "NA";
    else str = str.toString();

    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace(/(<([^>]+)>)/gi, " ");
  }
  const renderAction = ({ row }) => {
    const routeChange = (VC_CNT_URL) => {
      axios.get().then((response) => {});

      let path = `/watchml`;
      navigate(path, {
        state: {
          VC_CNT_URL: VC_CNT_URL,
        },
      });
    };
    let showProgramrowdescription = false;
    if (location.state.type === "Program") {
      showProgramrowdescription = true;
    }
    console.log("row FROM DETAILS-ML", row);
    return (
      row.VC_KAT_NM != null &&
      location.state.certificate_prgrm_flag != 1 && (
        <>
          <PlayArrow
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (row.NM_ATTMPTS == 0 && row.VC_KAT_NM != "Assignment") {
                swal({
                  text: "Attempts over",
                  type: "warning",
                  icon: "warning",
                });
                return;
              } else {
                if (
                  row.VC_KAT_NM === "HTML Content" ||
                  row.VC_KAT_NM === "Reference Link"
                ) {
                  if (row.VC_CNT_URL != null) {
                    if (row.IS_VIDEO === true) {
                      navigate("/watchml", {
                        state: {
                          VC_CNT_URL: row.VC_CNT_URL,
                        },
                      }); //send the url to the navigate page as props
                    } else {
                      let a = document.createElement("a");
                      a.href = row.VC_CNT_URL;
                      a.download = "testFile.docx";
                      a.target = "_self";
                      a.click();
                      // window.open(
                      //   `${row.VC_CNT_URL}`,
                      //   "_blank",
                      //   "noopener,noreferrer"
                      // );
                    }
                  }
                } else if (
                  row.VC_KAT_NM === "Assessment" ||
                  row.VC_KAT_NM === "Pre-Read"
                ) {
                  navigate("/assessment", {
                    state: {
                      course_id: location.state.course_id,
                      node_id: row.NM_Node_ID,
                      type: location.state.type,
                      course_name: location.state.course_name,
                      start_date: location.state.start_date,
                      end_date: location.state.end_date,
                      credit_hours: location.state.credit_hours,
                      progress: location.state.progress,
                      description: location.state.description,
                      VC_ILT_CD: location.state.VC_ILT_CD,
                      imgSrc: location.state.imgSrc,
                      topic_name: location.state.topic_name,
                      certificateID: location.state.certificateID,
                      DT_CMPLTN_DT: location.state.DT_CMPLTN_DT,
                    },
                  });
                } else if (
                  row.VC_KAT_NM === "Assignment" ||
                  row.VC_KAT_NM === "Home Work"
                ) {
                  navigate("/assignment", {
                    state: {
                      course_id: location.state.course_id,
                      node_id:
                        row.NM_Node_ID === undefined
                          ? location.state.node_id
                          : row.NM_Node_ID,
                      type: location.state.type,
                      course_name: location.state.course_name,
                      start_date: location.state.start_date,
                      end_date: location.state.end_date,
                      credit_hours: location.state.credit_hours,
                      progress: location.state.progress,
                      description: location.state.description,
                      VC_ILT_CD: location.state.VC_ILT_CD,
                      imgSrc: location.state.imgSrc,
                      topic_name: location.state.topic_name,
                      certificateID: location.state.certificateID,
                      DT_CMPLTN_DT: location.state.DT_CMPLTN_DT,
                    },
                  });
                } else if (row.VC_KAT_NM === "Prereading material") {
                  // if (row.NM_ATTMPTS > 10) {
                  //   alert("You have no attempt left.");
                  //   return;
                  // }
                  navigate("/prereadmaterial", {
                    state: {
                      course_id: location.state.course_id,
                      node_id:
                        row.NM_Node_ID === undefined
                          ? location.state.node_id
                          : row.NM_Node_ID, //location.state.node_id,
                      type: location.state.type,
                      progress: location.state.progress,
                      course_name: location.state.course_name,
                      description: location.state.description,
                      start_date: location?.state?.start_date?.split("T")[0],
                      end_date: location?.state?.end_date?.split("T")[0],
                      credit_hours: location.state.credit_hours,
                      VC_ILT_CD: location.state.VC_ILT_CD,
                      imgSrc: location.state.imgSrc,
                      topic_name: topic,
                      certificateID: location.state.certificateID,
                      DT_CMPLTN_DT: location.state.DT_CMPLTN_DT,
                    },
                  });
                } else if (row.VC_KAT_NM === "SCORM 1.2 Content") {
                  window.open(
                    `${LMS}/Control/publishScormCourse.aspx?CourseId=${
                      location.state.course_id
                    }&NodeId=${row.NM_Node_ID}&ContentId=${
                      row.NM_CNTNT_ID
                    }&UserId=${sessionStorage.getItem(
                      "userId"
                    )}&UserFirstName=${sessionStorage
                      .getItem("userFirstName")
                      .trim()}&UserLastName=${sessionStorage
                      .getItem("userLastName")
                      .trim()}&IPAddress=${IPAddress}`,
                    "_blank"
                  );
                } else if (row.VC_KAT_NM === "Classroom Training") {
                  navigate("/offerDetails", {
                    state: {
                      iltId: parseInt(`${row.NM_CNTNT_ID}`),
                    },
                  });
                }
                axios({
                  method: "post",
                  url: PUBLIC_URL + "/api/Program/MyLearning/P/TrackCourse",
                  data: {
                    courseLaunchId: location.state.course_id,
                    nodeId: parseInt(`${row.NM_Node_ID}`),
                    userId: sessionStorage.getItem("userId"),
                  },
                }).then(
                  (response) => {
                    getELearningProgramDetails();

                    let temp = 0;
                    EL_P_ML_data.forEach((element) => {
                      temp = temp + element.NM_PRGRSS;
                    });
                    let newProgress = temp / elearning_programNodesCount;
                    if (newProgress !== undefined) {
                      setpageLevelCourseProgress(newProgress);
                    }
                  },
                  (error) => {
                    console.log(error);
                  }
                );
              }
            }}
          />
          {showProgramrowdescription && (
            //Added by Saurav
            <BasicModel
              Description={"Name : " + row?.VC_Node_NM + " "}
              Description2ndline={"Description : " + " " + row?.VC_CRS_DSCRPTN}
            />
          )}
        </>
      )
    );
  };

  const [actionColumn] = useState(["action"]);
  const [columns] = useState([
    {
      name: "VC_Node_NM",
      title: `${t("rbkey_CrsNmLBL")}`,
      getCellValue: (row) => (row.VC_Node_NM === null ? "NA" : row.VC_Node_NM),
    },
    {
      name: "VC_KAT_NM",
      title: `${t("rbkey_TypLBL")}`,
      getCellValue: (row) => (row.VC_KAT_NM === null ? "NA" : row.VC_KAT_NM),
    },
    {
      name: "DT_LST_ACCSS_DT.split('T')[0]",
      title: `${t("rbkey_LstAccssdDtLBL")}`,
      getCellValue: (row) =>
        row.DT_LST_ACCSS_DT == null
          ? "NA"
          : formatTime(row.DT_LST_ACCSS_DT, "date"), //row.DT_LST_ACCSS_DT.split("T")[0],
    },
    {
      name: "NM_ATTMPTS",
      title: `${t("rbkey_AttmptsLftLBL")}`,
      getCellValue: (row) =>
        // row.NM_ATTMPTS == null ? "NA" : 10 - row.NM_ATTMPTS + "/10",
        row.NM_ATTMPTS == null ||
        row.VC_KAT_NM == "Assignment" ||
        row.VC_KAT_NM == "Home Work"
          ? "NA"
          : row.NM_ATTMPTS,
    },
    {
      name: "NM_PRGRSS",
      title: `${t("rbkey_SttsLBL")}`,
      getCellValue: (row) =>
        row.NM_PRGRSS == null
          ? location.state.progress !== null &&
            location.state.progress !== undefined &&
            location.state.progress !== ""
            ? "NA"
            : location.state.NM_PRGRSS
          : row.NM_PRGRSS,
    },
    {
      name: "action",
      title: `${t("rbkey_ActnLBL")}`,
      getCellValue: (row) =>
        EL_P_ML_data.filter((item) => item.NM_Node_ID === row.NM_Node_ID),
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: "VC_Node_NM", width: 300 },
    { columnName: "VC_Node_NM", fontWeight: "bold" },
  ]);

  const errorMessageAlert = (message) => {
    return (
      <Stack sx={{ width: "100%" }} spacing={2}>
        <Alert
          severity="error"
          onClose={() => {
            setisValidationErrorPresent(false);
            validationErrorMessage("");
          }}
        >
          <AlertTitle>Alert</AlertTitle>
          {message}
        </Alert>
      </Stack>
    );
  };

  useEffect(() => {
    axios
      .get(PUBLIC_URL_API2 + "/api/Authenticate/GetIP")
      .then((res) => {
        setIPAddress(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleWithdraw = (event) => {
    if (classroomData?.Table[0]?.DT_END_CNCLTN_DT != null) {
      let cancelDate = classroomData.Table[0].DT_END_CNCLTN_DT.split("T")[0];
      let TodaysDate = new Date().toISOString().slice(0, 10);
      if (cancelDate < TodaysDate) {
        //show the alert message
        setisValidationErrorPresent(true);
        setvalidationErrorMessage(t("rbkey_EndDtCnclctnMsg"));
        errorMessageAlert(validationErrorMessage);
      }
    } else {
      setdialogBoxOpen(true);
    }
  };

  useEffect(() => {
    if (
      location.state.progress !== undefined &&
      location.state.progress !== null
    ) {
      setpageLevelCourseProgress(location.state.progress);
    }
  }, [props]);

  const getClassRoomTrainingDetails = async () => {
    await axios
      .get(PUBLIC_URL + "/api/ClassRoomTraining/MyLearning/ILTDetail", {
        params: {
          corporateId:
            sessionStorage.getItem("userDomain") !== null
              ? sessionStorage.getItem("userDomain")
              : DOMAIN_ID,
          iLTId:
            location.state.isDetail === "true"
              ? location.state.childId
              : location.state.course_id,
          userId: sessionStorage.getItem("userId"),
          culture: "en-US",
        },
      })
      .then((res) => {
        if (res.data.length === 0) {
          setEL_P_ExpiredFlag(true);
        } else {
          setclassroomData(res.data);
          getStatus(res.data.Table2[0].CH_ILT_STTS);
          getNominationStatus(res.data.Table3[0].NMNTN_STTS);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const calculateProgress = (nodeCount, nodeProgressSum) => {
    let newProgress = nodeProgressSum / nodeCount;
    setpageLevelCourseProgress(newProgress);
  };

  const getELearningProgramDetails = async () => {
    await axios
      .get(PUBLIC_URL + "/api/Program/MyLearning/Program/Details", {
        params: {
          naRbkey: "NA",
          courseLaunchId: location.state.course_id,
          userId: sessionStorage.getItem("userId"),
          selectedCultureMlt: "en-US",
        },
      })
      .then((res) => {
        if (res.data.length > 0) {
          setprogramData(res.data);
          setEL_P_ML_data(res.data);
          setelearning_programNodesCount(res.data.length);
          let temp = 0;
          res.data.forEach((element) => {
            temp = temp + element.NM_PRGRSS;
          });
          calculateProgress(res.data.length, temp);

          //setting topic name in a variable
          if (location.state.topic_name !== "") {
            setTopic(location.state.topic_name);
          } else {
            res.data.Table?.map((item) =>
              item.VC_ILT_KND_NM !== undefined
                ? setTopic(item.VC_ILT_KND_NM)
                : setTopic("NA")
            );
          }
        } else {
          setEL_P_ExpiredFlag(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (
      location.state.type === "Program" ||
      location.state.type === "eLearning"
    ) {
      getELearningProgramDetails();
    } else {
      getClassRoomTrainingDetails();
    }
  }, []);

  useEffect(() => {
    if (
      location.state.type === "Program" ||
      location.state.type === "eLearning"
    ) {
      setelearningProgramGrid(true);
      setclassroomTrainingGrid(false);
    } else {
      setelearningProgramGrid(false);
      setclassroomTrainingGrid(true);
    }
  });

  const handleClickStart = (row) => {
    setPopup(!popup);
    axios
      .get(PUBLIC_URL + "/api/ELearning/MyLearning/EL/GetAssessmentDetails", {
        params: {
          courseLaunchId: location.state.course_id,
          nodeId: row.NM_Node_ID,
          userId: sessionStorage.getItem("userId"),
        },
      })
      .then((res) => {
        let temp = res.data.Table.concat(
          res.data.Table1,
          res.data.Table2,
          res.data.Table3
        );

        setLoading(true);
        setAssessmentDetails(res);
        nodeId = row.NM_Node_ID;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDialogBoxClose = () => {
    if (withdrawalReason === "" || withdrawDropdownValue === "") {
      withdrawalReason === ""
        ? setwithdrawReasonTextHasError(true)
        : setwithdrawReasonTextHasError(false);
      withdrawDropdownValue === ""
        ? setwithdrawDropdownHasError(true)
        : setwithdrawDropdownHasError(false);
    } else {
      setdialogBoxOpen(false);
      setwithdrawReasonTextHasError(false);
      setwithdrawDropdownHasError(false);
      axios
        .post(
          PUBLIC_URL +
            "/api/ClassRoomTraining/MyLearning/ILTNominationWithdraw",
          {
            learnerId: sessionStorage.getItem("userId"),
            //iltId: location.state.course_id,
            iltId: location.state.iltId,
            withdrawReason: withdrawalReason,
            withdrawValue: withdrawDropdownValue,
            cancelledBy: sessionStorage.getItem("userId"),
          }
        )
        .then((response) => {
          setgblEnrollDisable(false);
          navigate("/availablelearning");
        });
    }
  };

  const popupFunction = () => {
    if (popup) {
      return <Assessment></Assessment>;
    } else {
      return null;
    }
  };

  const getStatus = (CH_ILT_STTS) => {
    if (CH_ILT_STTS === "I") {
      setStatus(t("rbkey_VcblryInitialLBL"));
    } else if (CH_ILT_STTS === "L") {
      setStatus(t("rbkey_VcblryLaunchedLBL"));
    } else if (CH_ILT_STTS === "R") {
      setShowWithdrawButton(false);
      setStatus(t("rbkey_VcblryRunningLBL"));
    } else if (CH_ILT_STTS === "X") {
      setShowWithdrawButton(false);
      setStatus(t("rbkey_ClsdLBL"));
    } else if (CH_ILT_STTS === "C") {
      setShowWithdrawButton(false);
      setStatus("rbkey_VcblryCanceledLBL");
    } else if (CH_ILT_STTS === "F") {
      setShowWithdrawButton(false);
      setStatus("rbkey_VcblryFinishedLBL");
    }
  };

  const getNominationStatus = (NMNTN_STTS) => {
    if (NMNTN_STTS === "P") {
      setcurrentNominationStatus(t("rbkey_PndngLBL"));
    } else if (NMNTN_STTS === "W") {
      setcurrentNominationStatus(t("rbkey_WtngLBL"));
    } else if (NMNTN_STTS === "C") {
      setcurrentNominationStatus(t("rbkey_CnfrmdLBL"));
    } else if (NMNTN_STTS === "X") {
      setcurrentNominationStatus(t("rbkey_VcblryCanceledLBL"));
      setShowWithdrawButton(false);
    } else if (NMNTN_STTS === "R") {
      setShowWithdrawButton(false);
      setcurrentNominationStatus(t("rbkey_RjctdLBL"));
    } else if (NMNTN_STTS === "M") {
      setcurrentNominationStatus(t("rbkey_PndngWthRprtngAthrtyLBL"));
    } else if (NMNTN_STTS === "D") {
      setcurrentNominationStatus(t("rbkey_RjctdByRprtngAthrtyLBL"));
      setShowWithdrawButton(false);
    } else if (NMNTN_STTS === "E") {
      setcurrentNominationStatus(t("rbkey_AwtdCnfrmtnLBL"));
    } else if (NMNTN_STTS === "S") {
      setcurrentNominationStatus(t("rbkey_NmntdByRprtngAthrtyLBL"));
    } else if (NMNTN_STTS === "I") {
      setcurrentNominationStatus(t("rbkey_IgnrdLBL"));
    } else if (NMNTN_STTS === "B") {
      setcurrentNominationStatus(t("rbkey_DclndLBL"));
    } else if (NMNTN_STTS === "Y") {
      setcurrentNominationStatus(t("rbkey_CncltnPendingLbl"));
      setShowWithdrawButton(false);
    } else if (NMNTN_STTS === "L") {
      setcurrentNominationStatus("Launched");
    }
  };
  // useEffect(() => {
  //   if (classroomData.Table !== undefined && classroomData.Table.length > 0) {
  //     //Setting status
  //     //setting nomination status
  //   }
  // }, [classroomData]);

  const current = new Date();
  var year = current.getFullYear();
  var month = current.getMonth() + 1;
  var day = current.getDate();

  if (month < 10) {
    month = "0" + month;
  }
  if (day < 10) {
    day = "0" + day;
  }

  const currDate = `${day}/${month}/${year}`;

  const MenuItem = withStyles({
    root: {
      justifyContent: "flex-start",
    },
  })(MuiMenuItem);

  return (
    <div>
      <Navbar />
      {isValidationErrorPresent && errorMessageAlert(validationErrorMessage)}
      <div>
        <Dialog
          fullWidth
          open={dialogBoxOpen}
          onClose={handleDialogBoxClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Please Specify Withdrawal Reason"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {/* add the input forms here */}
              <div>
                <Box sx={{ p: 3 }}>
                  <FormControl fullWidth>
                    <FormHelperText className="formHelperText">
                      Withdraw Reason
                    </FormHelperText>

                    <Select
                      name="withdraw_dropdown"
                      id="withdraw_dropdown"
                      value={withdrawDropdownValue}
                      onChange={(e) => {
                        setwithdrawDropdownValue(e.target.value);
                      }}
                    >
                      <MenuItem value={t("rbkey_IllnessLbl")}>
                        {t("rbkey_IllnessLbl")}
                      </MenuItem>
                      <MenuItem value={t("rbkey_OtherReasonLbl")}>
                        {t("rbkey_OtherReasonLbl")}
                      </MenuItem>
                      <MenuItem value={t("rbkey_PersonalLbl")}>
                        {t("rbkey_PersonalLbl")}
                      </MenuItem>
                      <MenuItem value={t("rbkey_DisasterLbl")}>
                        {t("rbkey_DisasterLbl")}
                      </MenuItem>
                      <MenuItem value={t("rbkey_BusinessLbl")}>
                        {t("rbkey_BusinessLbl")}
                      </MenuItem>
                    </Select>
                    {withdrawDropdownHasError && (
                      <FormHelperText className="formHelperText">
                        This is required!
                      </FormHelperText>
                    )}
                  </FormControl>
                </Box>
              </div>

              <div>
                <Box sx={{ p: 3 }}>
                  <FormHelperText> Comments </FormHelperText>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    id="withdrawal_reason"
                    label=""
                    variant="outlined"
                    value={withdrawalReason}
                    onChange={(e) => {
                      setwithdrawalReason(e.target.value);
                    }}
                  />
                  {withdrawReasonTextHasError && (
                    <FormHelperText>This is required!</FormHelperText>
                  )}
                </Box>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogBoxClose}>Submit</Button>
            <Button
              onClick={() => {
                setdialogBoxOpen(false);
                setwithdrawalReason("");
                setwithdrawDropdownValue("");
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        {popupFunction}

        {EL_P_ExpiredFlag ? (
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert severity="error">
              {location.state.type === "eLearning" ||
                (location.state.type === "Program" && (
                  <>
                    <AlertTitle>Expiry Alert</AlertTitle>
                    {t("rbkey_ExprdNtfctnsMSG")}
                  </>
                ))}
              {location.state.type === "ClassRoomTraining" && (
                <>
                  <AlertTitle>Access Denied</AlertTitle>
                  {t("rbkey_AccssNtfctnsMSG")}
                </>
              )}
            </Alert>
          </Stack>
        ) : (
          <div>
            {/* <Button
              onClick={() => {
                navigate("/mylearning");
              }}
            >
              {t("rbkey_BckLBL")}
            </Button> */}
            <Button onClick={() => navigate(-1)}>{t("rbkey_BckLBL")}</Button>
            <Container fluid>
              <Row>
                <Col>
                  <Card>
                    <Card.Header as="h5" className="CardHeader">
                      <span>
                        <span>{t("rbkey_LrnngCntr")}</span> |{" "}
                        <span> {t("rbkey_MyECrssLBL")}</span>
                      </span>
                      <span
                        title={
                          location.state.type === "eLearning"
                            ? `${t("rbkey_eLrningMSG")}`
                            : location.state.type === "Program"
                            ? `${t("rbkey_prgrmMSG")}`
                            : `${t("rbkey_ILTMSG")}`
                        }
                        style={{ float: "right" }}
                      >
                        {location.state.type === "eLearning"
                          ? `${t("rbkey_ELrrngLbl")}`
                          : location.state.type === "Program"
                          ? `${t("rbkey_PrgrmLbL")}`
                          : `${t("rbkey_ClssrmTrnngsLBL")}`}
                      </span>
                    </Card.Header>
                    <Card.Body>
                      {elearningProgramGrid && (
                        <Row>
                          <Col sm={2}>
                            <img
                              className="offerLogo"
                              src={location.state.imgSrc}
                            />
                          </Col>

                          <Col sm={8}>
                            <Row style={{ padding: "0 0 2% 0" }}>
                              <Col>
                                <span style={{ fontWeight: "bold" }}>
                                  {location.state.course_name}{" "}
                                </span>
                              </Col>
                            </Row>

                            <Row style={{ padding: "0 0 2% 0" }}>
                              <Col>
                                <span>
                                  {t("rbkey_launchDate_LBL")} :{" "}
                                  {/* {location?.state?.start_date?.split("T")[0]} */}
                                  {formatTime(
                                    location?.state?.start_date,
                                    "date"
                                  )}
                                </span>
                              </Col>

                              {!location.state.isAvailableForever && (
                                <Col>
                                  <span>
                                    {t("rbkey_availableTill_LBL")} :{" "}
                                    {location?.state?.end_date?.split("T")[0]}
                                  </span>
                                </Col>
                              )}
                              <Col>
                                <span>
                                  {t("rbkey_CrdtHrsLBL")} :{" "}
                                  {location.state.credit_hours}
                                </span>
                              </Col>
                            </Row>
                            <Row style={{ padding: "0 0 2% 0" }}>
                              <Col>
                                {location.state.type == "eLearning" ? (
                                  <span>{t("rbkey_ElrnngCrsCdLBL")} : </span>
                                ) : (
                                  <span>{t("rbkey_PrgrmCdLbl")} : </span>
                                )}
                                {location.state.VC_ILT_CD}
                              </Col>
                              <Col>
                                {location.state.type == "Program" && (
                                  <span>
                                    No. of Content : {EL_P_ML_data.length}
                                  </span>
                                )}
                              </Col>
                            </Row>
                            <Row style={{ padding: "0 0 2% 0" }}>
                              <Col>
                                <span>
                                  {t("rbkey_TpcLBL")} :{" "}
                                  {location.state.topic_name !== ""
                                    ? location.state.topic_name
                                    : EL_P_ML_data.Table?.map((item) =>
                                        item.VC_ILT_KND_NM !== undefined
                                          ? item.VC_ILT_KND_NM
                                          : "NA"
                                      )}
                                </span>
                              </Col>
                            </Row>
                            <Row style={{ padding: "0 0 2% 0" }}>
                              <Col sm={4}>
                                <span>{t("rbkey_PrgrssFrMblLBL")} </span>
                              </Col>

                              <Col sm={7}>
                                <ProgressBar
                                  now={location.state.progress}
                                  label={`${location.state.progress}%`}
                                  variant="PROGRESSBAR_COLOR"
                                  style={{ marginTop: 3 }}
                                />
                              </Col>
                              <Col sm={1} style={{ padding: "0 0 2% 0" }}>
                                {location.state.progress === 0 && "0%"}
                              </Col>
                            </Row>
                            <Row style={{ padding: "0 0 2% 0" }}>
                              <Col>
                                <span>
                                  {" "}
                                  {t("rbkey_DscrptnLBL")} :{" "}
                                  {location.state.description === undefined
                                    ? "NA"
                                    : location.state.description}
                                </span>
                              </Col>
                            </Row>

                            {location.state.certificateID > 0 && (
                              <>
                                {location.state.progress == 100 && (
                                  <Row style={{ padding: "0 0 2% 0" }}>
                                    <Col>
                                      <CloudDownload
                                        style={{ "margin-right": "1%" }}
                                        title="Certificate"
                                        onClick={() => {
                                          if (
                                            location.state.certificateID > 0
                                          ) {
                                            navigate("/certificatepage", {
                                              state: {
                                                VC_ILT_CRS_LNCH_NM:
                                                  location.state.course_name,
                                                VC_USR_NM: `${sessionStorage.getItem(
                                                  "userFirstName"
                                                )} ${sessionStorage.getItem(
                                                  "userLastName"
                                                )}`,
                                                // DT_CMPLTN_DT:
                                                //   location.state.DT_CMPLTN_DT,
                                                DT_CMPLTN_DT:
                                                  location.state.DT_CMPLTN_DT ==
                                                  null
                                                    ? ""
                                                    : location.state.DT_CMPLTN_DT.substring(
                                                        0,
                                                        10
                                                      ),
                                                certificate_id:
                                                  location.state.certificate_id,
                                                certificate_prnt_flg:
                                                  location.state
                                                    .certificate_prnt_flg,
                                                course_id:
                                                  location.state.course_id,
                                              },
                                            });
                                          }
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                )}
                              </>
                            )}
                          </Col>
                        </Row>
                      )}
                      {classroomTrainingGrid && (
                        <>
                          <Row style={{ padding: "0 0 2% 0" }}>
                            <Col sm={2}>
                              <img
                                className="offerLogo"
                                src={location.state.imgSrc}
                              />
                            </Col>
                            <Col sm={8}>
                              <div style={{ padding: "0 0 2% 0" }}>
                                <span style={{ fontWeight: "bold" }}>
                                  {classroomData.Table?.map(
                                    (item) => item.VC_ILT_NM
                                  )}
                                </span>
                              </div>
                              <Row style={{ padding: "0 0 2% 0" }}>
                                <Col>
                                  <span style={{ fontWeight: "bold" }}>
                                    {t("rbkey_launchDate_LBL")} :{" "}
                                  </span>
                                  <span>
                                    {classroomData.Table?.map((item) =>
                                      // item?.DT_STRT_DT?.split("T")[0]
                                      formatTime(item?.DT_STRT_DT, "date")
                                    )}
                                  </span>
                                </Col>
                                <Col>
                                  <span style={{ fontWeight: "bold" }}>
                                    {t("rbkey_availableTill_LBL")} :{" "}
                                  </span>
                                  <span>
                                    {classroomData.Table?.map((item) =>
                                      //item?.DT_END_DT?.split("T")[0]
                                      formatTime(item?.DT_END_DT, "date")
                                    )}
                                  </span>
                                </Col>
                              </Row>
                              <Row style={{ padding: "0 0 2% 0" }}>
                                <Col>
                                  <span style={{ fontWeight: "bold" }}>
                                    {t("rbkey_TrnngCdLbl")} :{" "}
                                  </span>
                                  {classroomData.Table?.map((item) =>
                                    item?.VC_ILT_CD === undefined
                                      ? location.state.VC_ILT_CD
                                      : item?.VC_ILT_CD
                                  )}
                                </Col>
                                <Col>
                                  <span style={{ fontWeight: "bold" }}>
                                    {t("rbkey_CrdtHrsLBL")} :{" "}
                                  </span>
                                  <span>
                                    {classroomData.Table?.map(
                                      (item) => item.NM_CRDT_HRS
                                    )}
                                  </span>
                                </Col>
                              </Row>
                              <Row style={{ padding: "0 0 2% 0" }}>
                                <Col>
                                  <span style={{ fontWeight: "bold" }}>
                                    No. of sessions:{" "}
                                  </span>
                                  {classroomData.Table1?.length}
                                </Col>
                                <Col>
                                  <span style={{ fontWeight: "bold" }}>
                                    {t("rbkey_TpcLBL")} :{" "}
                                  </span>
                                  {classroomData.Table?.map((item) =>
                                    item.VC_ILT_KND_NM !== null
                                      ? item.VC_ILT_KND_NM
                                      : location.state.topic_name === null
                                      ? "NA"
                                      : location.state.topic_name
                                  )}
                                </Col>
                              </Row>
                              <Row style={{ padding: "0 0 2% 0" }}>
                                <Col>
                                  <span style={{ fontWeight: "bold" }}>
                                    {" "}
                                    {t("rbkey_DscrptnLBL")} :{" "}
                                  </span>
                                  {classroomData.Table?.map((item) =>
                                    item.VC_DSCRPTN !== null
                                      ? removeTags(item.VC_DSCRPTN)
                                      : location.state.description === null
                                      ? "NA"
                                      : location.state.description
                                  )}
                                </Col>
                              </Row>
                            </Col>
                            <Col sm={2}>
                              {
                                showWithdrawButton && (
                                  <>
                                    {classroomData.Table?.map((item) =>
                                      item?.DT_END_DT?.split("T")[0]
                                        .split("-")
                                        .reverse()
                                        .join("/") > currDate ? (
                                        <Button
                                          onClick={handleWithdraw}
                                          style={{ background: "red" }}
                                          variant="contained"
                                        >
                                          {t("rbkey_WthdrwlLBL")}
                                        </Button>
                                      ) : (
                                        <></>
                                      )
                                    )}
                                  </>
                                )
                                // )
                              }
                            </Col>
                          </Row>

                          <Row style={{ padding: "0 0 2% 0" }}>
                            <Col>
                              <Box sx={{ border: 1, padding: "1% 1% 1% 1%" }}>
                                <Row style={{ padding: "0 0 2% 0" }}>
                                  <Col sm={6}>
                                    <span style={{ fontWeight: "bold" }}>
                                      {t("rbkey_CrrntNmntnSttsLBL")} :{" "}
                                    </span>
                                    <span>{currentNominationStatus}</span>
                                    <br />
                                  </Col>
                                  <Col sm={6}>
                                    <span style={{ fontWeight: "bold" }}>
                                      {t("rbkey_SttsLBL")} :{" "}
                                    </span>
                                    <span>{status}</span>
                                  </Col>
                                </Row>
                              </Box>
                            </Col>
                          </Row>
                        </>
                      )}
                      {elearningProgramGrid && (
                        <Paper>
                          <Grid
                            rows={EL_P_ML_data}
                            columns={columns}
                            autoExpandAll={true}
                          >
                            <RowDetailState defaultExpandedRowIds={[0, 1]} />
                            <DataTypeProvider
                              for={actionColumn}
                              formatterComponent={renderAction}
                            />
                            <TreeDataState
                              expanded={true}
                              toggleRowExpanded={true}
                            />
                            <CustomTreeData getChildRows={getChildRows} />
                            <TableDevExpress
                              columnExtensions={tableColumnExtensions}
                            />
                            <TableHeaderRow />
                            <TableTreeColumn
                              for="VC_Node_NM"
                              expanded={true}
                              visible={false}
                            />
                          </Grid>
                        </Paper>
                      )}

                      {/* classroom training grid */}
                      {classroomTrainingGrid && (
                        <Row>
                          <Col>
                            <TableContainer component={Paper}>
                              <Table aria-label="collapsible table">
                                <TableHead>
                                  <TableRow>
                                    <TableCell style={{ fontWeight: "Bold" }}>
                                      {t("rbkey_SssnNmLBL")}
                                    </TableCell>
                                    <TableCell
                                      style={{ fontWeight: "Bold" }}
                                      align="right"
                                    >
                                      {t("rbkey_DscrptnLBL")}
                                    </TableCell>
                                    <TableCell
                                      style={{ fontWeight: "Bold" }}
                                      align="right"
                                    >
                                      {t("rbkey_DtLBL")}
                                    </TableCell>
                                    <TableCell
                                      style={{ fontWeight: "Bold" }}
                                      align="right"
                                    >
                                      {t("rbkey_StrtTmLBL")}
                                    </TableCell>
                                    <TableCell
                                      style={{ fontWeight: "Bold" }}
                                      align="right"
                                    >
                                      {t("rbkey_EndTmLBL")}
                                    </TableCell>
                                    <TableCell
                                      style={{ fontWeight: "Bold" }}
                                      align="right"
                                    >
                                      {t("rbkey_VnLBL")}
                                    </TableCell>
                                    <TableCell
                                      style={{ fontWeight: "Bold" }}
                                      align="right"
                                    >
                                      {t("rbkey_InstrctrNmLBL")}
                                    </TableCell>
                                    <TableCell
                                      style={{ fontWeight: "Bold" }}
                                      align="right"
                                    >
                                      {t("rbkey_AttchmntLBL")}
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {classroomData?.Table1?.map((row) => (
                                    <ClassRoomRowMUI
                                      key={row.NM_SSN_ID}
                                      row={row}
                                    />
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Col>
                        </Row>
                      )}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        )}
      </div>
    </div>
  );
};
